import { graphql, useStaticQuery } from "gatsby"

const useManagementTraineesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicManagementTraineesPage {
        data {
          background_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
          }
          headline {
            text
          }
          subheadline {
            text
          }
          text {
            raw
          }
          first_cta_link_text
          first_cta_title {
            text
          }
          first_cta_link {
            url
          }
          cards {
            card_color
            card_icon {
              alt
              copyright
              url
              gatsbyImageData
            }
            card_title {
              text
            }
          }
          second_cta_link_text
          second_cta_title {
            text
          }
          second_cta_link {
            url
          }
          testimonials_subtitle {
            text
          }
          testimonials_title {
            text
          }
          testimonials {
            testimonial {
              document {
                ... on PrismicManagementDevelopmentProgramTestimonial {
                  id
                  data {
                    name {
                      text
                    }
                    picture {
                      alt
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            quality: 100
                            layout: CONSTRAINED
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                    position {
                      text
                    }
                    text {
                      text
                    }
                  }
                }
              }
            }
          }
          opportunities_headline {
            text
          }
          opportunities_link_text
          opportunities_link {
            url
          }
          opportunities_text {
            text
          }
          opportunities {
            opportunity {
              document {
                ... on PrismicManagementDevelopmentProgramOpportunity {
                  id
                  data {
                    title {
                      text
                    }
                    link {
                      url
                    }
                    description {
                      text
                    }
                  }
                }
              }
            }
          }
          bottom_link {
            url
          }
          bottom_link_text
        }
      }
    }
  `)

  return data.prismicManagementTraineesPage.data
}

export default useManagementTraineesPage
