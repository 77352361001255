// extracted by mini-css-extract-plugin
export var background = "index-module--background--91840";
export var background__img = "index-module--background__img--91a1a";
export var bottomBtn = "index-module--bottomBtn--4d32b";
export var desktopHidden = "index-module--desktopHidden--481c4";
export var info = "index-module--info--b3890";
export var infoWrapper = "index-module--infoWrapper--11668";
export var info__cards = "index-module--info__cards--49653";
export var info__cta = "index-module--info__cta--f2659";
export var info__ctaHeadline = "index-module--info__ctaHeadline--5eea7";
export var info__ctaSecond = "index-module--info__ctaSecond--126df";
export var info__headline__desktop = "index-module--info__headline__desktop--d8105";
export var info__headline__mobile = "index-module--info__headline__mobile--26c46";
export var info__subheadline = "index-module--info__subheadline--67c93";
export var info__text = "index-module--info__text--8d96a";
export var main = "index-module--main--40016";
export var main__button = "index-module--main__button--cbd11";
export var main__companies = "index-module--main__companies--25bdb";
export var main__headline = "index-module--main__headline--47392";
export var main__logos = "index-module--main__logos--bce5b";
export var main__paragraph = "index-module--main__paragraph--f187c";
export var mobileHidden = "index-module--mobileHidden--82f86";
export var root = "index-module--root--9f98b";
export var testimonial__desktop = "index-module--testimonial__desktop--39043";
export var testimonial__mobile = "index-module--testimonial__mobile--f84dd";
export var wrapper = "index-module--wrapper--78933";