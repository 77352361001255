import React, { useState, useMemo } from "react"
import { RichText } from "prismic-reactjs"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"

import Accordion from "~/components/ui/accordion"
import Container from "~/components/ui/container"
import Testimonials from "~/components/ui/testimonials"
import Button from "~/components/ui/button"
import InfoCard from "~/components/ui/info-card"
import Link from "~/components/link"

import Urus from "~/images/internships/urus.svg"
import Alta from "~/images/internships/alta.svg"
import Genex from "~/images/internships/genex.svg"
import Peak from "~/images/internships/peak.svg"
import SCCL from "~/images/internships/sccl.svg"
import Vas from "~/images/internships/vas.svg"
import AgSource from "~/images/internships/ag-source.svg"
import TransOva from "~/images/internships/trans-ova.svg"

import * as styles from "./index.module.scss"

import useManagementTraineesPage from "~/hooks/cms/useManagementTraineesPage"
import { useHeadline } from "~/hooks/useHeadline"

const HeroSection: React.FC = () => {
  const [index, setIndex] = useState(0)
  const pageData = useManagementTraineesPage()

  const cards = pageData.cards.map(card => {
    return (
      <InfoCard
        key={card.card_title.text}
        variant="green"
        color={card.card_color}
        title={card.card_title.text}
        icon={card.card_icon}
      />
    )
  })

  const headlineNode = useHeadline(pageData.headline)

  const opportunities = useMemo(() => {
    return pageData.opportunities.map(node => {
      return node.opportunity.document.data
    })
  }, [])

  return (
    <section className={styles.root}>
      <div className={styles.background}>
        {pageData.background_image && pageData.background_image.localFile && (
          <div className={styles.background__img}>
            <GatsbyImage
              alt={pageData.background_image.alt || ""}
              image={
                pageData.background_image.localFile.childImageSharp
                  .gatsbyImageData
              }
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </div>
        )}
        <div className={styles.desktopHidden}>
          <Container>
            <h1
              className={classNames(
                "headline--decorated",
                styles.info__headline__mobile
              )}
            >
              {headlineNode}
            </h1>
          </Container>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.infoWrapper}>
          <Container>
            <div className={styles.info}>
              <h1
                className={classNames(
                  "headline--decorated",
                  styles.info__headline__desktop
                )}
              >
                {headlineNode}
              </h1>
              <h2 className={styles.info__subheadline}>
                {pageData.subheadline.text}
              </h2>
              <div className={styles.info__text}>
                <RichText render={pageData.text.raw} />
              </div>
              <div className={styles.info__cta}>
                {pageData.first_cta_title.text && (
                  <h3
                    className={classNames(
                      "headline--decorated",
                      styles.info__ctaHeadline
                    )}
                  >
                    {pageData.first_cta_title.text}
                  </h3>
                )}
                {pageData.first_cta_link_text && pageData.first_cta_link.url && (
                  <div className={styles.main__button}>
                    <Button
                      variant="orange"
                      withCustomDimensions={false}
                      isLink={true}
                      to={pageData.first_cta_link.url}
                    >
                      {pageData.first_cta_link_text}
                    </Button>
                  </div>
                )}
              </div>
              {cards.length > 0 && (
                <div
                  className={classNames(
                    styles.info__cards,
                    styles.mobileHidden
                  )}
                >
                  {cards}
                </div>
              )}
              <div
                className={classNames(styles.info__cta, styles.mobileHidden)}
              >
                <h3
                  className={classNames(
                    "headline--decorated",
                    styles.info__ctaHeadline
                  )}
                >
                  {pageData.second_cta_title.text}
                </h3>
                {pageData.second_cta_link_text && pageData.second_cta_link.url && (
                  <div className={styles.main__button}>
                    <Button
                      to={pageData.second_cta_link.url}
                      withCustomDimensions={false}
                      isLink={true}
                    >
                      {pageData.second_cta_link_text}
                    </Button>
                  </div>
                )}
              </div>
              <div className={styles.testimonial__desktop}>
                <Testimonials
                  title={pageData.testimonials_title.text}
                  subtitle={pageData.testimonials_subtitle.text}
                  picture={
                    pageData.testimonials[index].testimonial.document.data
                      .picture
                  }
                  name={
                    pageData.testimonials[index].testimonial.document.data.name
                      .text
                  }
                  university={
                    pageData.testimonials[index].testimonial.document.data
                      .position.text
                  }
                  text={
                    pageData.testimonials[index].testimonial.document.data.text
                      .text
                  }
                  setIndex={setIndex}
                  index={index}
                  itemCount={pageData.testimonials.length}
                  maxItems={5}
                />
              </div>
              {pageData.bottom_link_text && pageData.bottom_link.url && (
                <div className={styles.mobileHidden}>
                  <div className={styles.bottomBtn}>
                    <Button
                      variant="orange"
                      withCustomDimensions={false}
                      isLink={true}
                      to={pageData.bottom_link.url}
                    >
                      {pageData.bottom_link_text}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Container>
        </div>

        <div className={styles.main}>
          <div className={styles.main__logos}>
            <div>
              <Urus />
            </div>
            <div>
              <AgSource />
            </div>
            <div>
              <Alta />
            </div>
            <div>
              <Genex />
            </div>
            <div>
              <Peak />
            </div>
            <div>
              <SCCL />
            </div>
            <div>
              <TransOva />
            </div>
            <div>
              <Vas />
            </div>
          </div>
          {pageData.opportunities_headline.text && (
            <h3 className={styles.main__headline}>
              {pageData.opportunities_headline.text}
            </h3>
          )}
          {pageData.opportunities_link.url && pageData.opportunities_link_text && (
            <div className={styles.main__button}>
              <Button
                to={pageData.opportunities_link.url}
                variant="orange"
                withCustomDimensions={false}
                isLink={true}
              >
                {pageData.opportunities_link_text}
              </Button>
            </div>
          )}
          {pageData.opportunities_text.text && (
            <p className={styles.main__paragraph}>
              {pageData.opportunities_text.text}
            </p>
          )}
          <Accordion items={opportunities} />
        </div>
        <div className={styles.desktopHidden}>
          <Container>
            {cards.length > 0 && (
              <div className={styles.info__cards}>{cards}</div>
            )}
            <div className={styles.info__ctaSecond}>
              <h3
                className={classNames(
                  "headline--decorated",
                  styles.info__ctaHeadline
                )}
              >
                {pageData.second_cta_title.text}
              </h3>
              {pageData.second_cta_link.url && pageData.second_cta_link_text && (
                <div className={styles.main__button}>
                  <Button
                    to={pageData.second_cta_link.url}
                    withCustomDimensions={false}
                    isLink={true}
                  >
                    {pageData.second_cta_link_text}
                  </Button>
                </div>
              )}
            </div>
          </Container>
          <div className={styles.testimonial__mobile}>
            <Testimonials
              title={pageData.testimonials_title.text}
              subtitle={pageData.testimonials_subtitle.text}
              picture={
                pageData.testimonials[index].testimonial.document.data.picture
              }
              name={
                pageData.testimonials[index].testimonial.document.data.name.text
              }
              university={
                pageData.testimonials[index].testimonial.document.data.position
                  .text
              }
              text={
                pageData.testimonials[index].testimonial.document.data.text.text
              }
              setIndex={setIndex}
              index={index}
              itemCount={pageData.testimonials.length}
              maxItems={5}
            />
          </div>
          {pageData.bottom_link_text && pageData.bottom_link.url && (
            <div className={styles.bottomBtn}>
              <Button
                variant="orange"
                withCustomDimensions={false}
                isLink={true}
                to={pageData.bottom_link.url}
              >
                {pageData.bottom_link_text}
              </Button>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default HeroSection
