import React, { ReactElement } from "react"
import combineClassNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./index.module.scss"
interface Props {
  variant: "green" | "dark-green" | "grey"
  title: string
  icon: any
  color?: string
}

export default function InfoCard({
  variant,
  icon,
  title,
  color,
}: Props): ReactElement {
  const rootClassNames = combineClassNames(styles.root, {
    [styles.root__green]: variant === "green",
    [styles.root__darkGreen]: variant === "dark-green",
    [styles.root__grey]: variant === "grey",
  })
  return (
    <div className={rootClassNames} style={{ backgroundColor: color }}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>{title}</h4>
        {icon.gatsbyImageData && (
          <div className={styles.iconWrapper}>
            <div className={styles.icon}>
              <GatsbyImage
                alt={icon.alt || ""}
                image={icon.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
